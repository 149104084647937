import Repo from './_repo.js'

export default class extends Repo {
  static create(checklistId, data) {
    return $tpu.r.data.restFetch(
      `/checklists/${checklistId}/checkItems`,
      'POST',
      data).then((result) => {
        if (result) { // Success
          // const checklist = $tpu.r.checklists.find(checklistId)
          // checklist.checkItems.push(result)

          $tpu.r.checklists.addItem(checklistId, result)
        }
        return result
      })
  }

  static update(cardId, checkItemId, updatedData) {
    return $tpu.r.data.restFetch(
      `/cards/${cardId}/checkItem/${checkItemId}`,
      'PUT',
      updatedData).then((result) => {
        if (result) { // Success
          const checkItem = this.find(checkItemId)
          Object.assign(checkItem, updatedData)

          // $tpu.r.checklists.updateItem(checkItemId, updatedData)
        }
        return result
      })
  }

  static destroy(cardId, checklistId, checkItemId) {
    return $tpu.r.data.restFetch(
      `/cards/${cardId}/checkItem/${checkItemId}`,
      'DELETE').then((result) => {
        if (result) { // Success
          $tpu.r.checklists.removeItem(checklistId, checkItemId)

          // const checklist = $tpu.r.checklists.find(checklistId)
          // checklist.checkItems = checklist.checkItems.filter(function(checkItem) {
          //   return checkItem.id != checkItemId;
          // })
        }

        return result
      })
  }

  static find(checkItemId) {
    return $tpu.r.checklists.cachedCheckItem(checkItemId)
  }
}
